.signUp.hg-theme-default{
    background-color: transparent;
}
.signUp.simple-keyboard.hg-layout-default .hg-button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1.7rem;
    border-radius: 0.9rem;
    height: 7.6vh;
    justify-content: center;
    padding: 0px;
    margin: 0.2rem;
}
.signUp.simple-keyboard.hg-theme-default .hg-row:nth-child(2) {
    margin-left: 2vw;
    margin-right: 2vw;
}
.signUp.simple-keyboard.hg-layout-default .hg-button.hg-yellow {
    background: #FFEA28;
    color: black;
    width: 13vw
  }
  .signUp.simple-keyboard.hg-layout-default .hg-button.hg-mail {
    width: 10vw;
    font-size: 15px;
}
.signUp.simple-keyboard.hg-layout-shift .hg-button.hg-number{
  width: 6vw;
  font-size: 18px;
}
.signUp.simple-keyboard.hg-layout-default .hg-button.hg-number{
  width: 6vw;
  font-size: 18px;
}
  .signUp.simple-keyboard.hg-layout-shift .hg-button.hg-mail {
    width: 10vw;
    font-size: 15px;
  }
.signUp.simple-keyboard.hg-theme-default .hg-row:nth-child(4) {
    margin-left: 3vw;
    margin-right: 3vw;
}

.signUP.hg-theme-default .hg-button{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1.7rem;
    border-radius: 0.9rem;
    height: 7.6vh;
    justify-content: center;
    padding: 0px;
    margin: 0.2rem;
}
.ant-checkbox-inner {
  width: 24px;
  height: 24px;
}
