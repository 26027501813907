.home.hg-theme-default{
    background-color: transparent;
}
.home.simple-keyboard.hg-layout-default .hg-button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1.7rem;
    border-radius: 0.9rem;
    height: 7.6vh;
    justify-content: center;
    padding: 0px;
    margin: 0.2rem;
}
.home.simple-keyboard.hg-theme-default .hg-row:nth-child(2) {
    margin-left: 2vw;
    margin-right: 2vw;
}

.home.hg-theme-default .hg-button{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1.7rem;
    border-radius: 0.9rem;
    height: 7.3vh;
    justify-content: center;
    padding: 0px;
    margin: 0.2rem;
}
.support-icon{
    font-size: 24px;
  height: auto;
  width: auto;
}