.table-body {
  width: 100%;
  background-color: #0079BA;
  height: 100%;
  color: black;
}

.table-btn {
  height: 8vh;
  width: 13vw;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2vh;
  text-align: center;
  font-size: 2.3vh;
  font-weight: 600;
}
.table-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    padding:20px 40px 40px 40px;
    overflow-y: auto;
    font-size: 16px;
    color: black;
  }
  .item {
    flex: 1 1 33.33%;
    padding-left: 10px;
  }
  .logout {
    float: right;
  }
  .logout > span {
    padding: 10px;
    font-weight: 400;
    font-size: 21px;
    cursor: progress;
    color: #fff;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even) {
    background-color: #dddddd;
  }