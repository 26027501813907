@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

body {
  margin: 0px;
  font-family: "Roboto Condensed";
}

::-webkit-scrollbar {
  display: none !important;
}

.adv-block {
  height: 100vh;
  background-color: #0083c6;
  text-align: center;
}

.adv-block-div {
  padding: 6rem 3rem;
}
/* .adv-gif-div {
      background-color: #ffffff;
    border-radius: 50%;
  }
   */
.adv-gif {
  width: 220px !important;
  height: 200px;
  margin: 2rem 0rem;
  border-radius: 50%;
}

.get-all {
  margin-bottom: 4rem;
  font-size: 12px;
  color: #ffffff;
}

.inputs-row {
  /* height: 100vh; */
  padding-top: 20px;
  text-align: center;
}

.shopping-with {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}

.inputs-block {
  height: 100vh;
  background-color: #ffffff;
  text-align: center;
}

.inputs-main-div {
  padding: 8rem 4rem;
}

.inputs-section {
  padding-left: 4rem;
  padding-right: 4rem;
}

.inputs-section .hello-again {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
}

.please-login {
  font-size: 12px;
  color: gray;
}

.user-input {
  border-radius: 4px !important;
}

.user-input::placeholder {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.continue-btn {
  width: 100%;
  background-color: #0083c6 !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid #0083c6;
}

.info-div {
  padding: 0.5rem 1.75rem;
  margin-bottom: 5rem;
  background: rgba(238, 251, 255, 0.65);
  border-radius: 4px;
}

.seeking {
  margin-bottom: 8px;
  font-size: 12px !important;
  font-weight: 600;
  color: #4c656f;
}

.for-any-help {
  margin-bottom: 10px;
  font-size: 10px !important;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.7);
}

.info-div-signup {
  padding: 0.5rem 1.75rem;
  background-color: aliceblue;
}

/* Home Screen */

.header-block {
  padding: 1rem 1.5rem 0.75rem;
}

.itemCode-input {
  font-size: 12px;
  border-radius: 5px;
  opacity: 1;
}

.itemCode-input::placeholder {
  font-size: 10px !important;
}

.user-details {
  text-align: end;
}

.user-name {
  margin-bottom: 0px;
  padding-left: 0.5rem;
  color: #00bcd4;
  font-weight: 600;
}

.info-block {
  padding: 6px;
  background-color: #ffd07b54;
  text-align: center;
}

.info-text {
  margin-bottom: 0px;
  font-size: 10px;
  font-weight: 600;
}

.items-block {
  height: 46vh;
  padding: 1rem 1rem 0rem 1.5rem;
  background-color: #f5f9fb;
  text-align: center;
}

.prodCardsScroll {
  height: 20vh;
}

.close-icon {
  width: 15px;
  position: relative;
  top: 0px;
  z-index: 1;
  left: 52px;
}

.prod-discount-home {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 15px;
  color: #019f3e;
}

.totalCard-home {
  border: 1px solid rgba(196, 196, 196, 0.4);
  box-shadow: 2px 3px 4px #ededed;
  border-radius: 10px;
}

.subtotal-details-div-home {
  padding: 3px 10px;
  margin-bottom: 4.5rem;
  background: #f5f9fb;
  border-radius: 6px;
}

.total-details-div-home {
  padding: 3px 10px;
  margin-bottom: 1rem;
  background: #f5f9fb;
  border-radius: 6px;
}

.discount-price-home {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #019f3e;
  text-align: right;
}

.continue-btn-cart-home {
  width: 100%;
  background-color: #0083c6 !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  border: 1px solid #0083c6;
}

.current-summary {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  text-align: left;
}

.cards-margin {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.productCard {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}

.empty-cart {
  height: 150px;
  width: 150px;
  display: block;
  margin: auto;
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
  border-radius: 50%;
}

.no-items {
  margin-top: 5px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 27px;
  color: #000000;
}

.start-adding {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
}

.suggestedItems-block {
  padding: 0.5rem 1rem;
  background: #ffffff;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.05);
}

.suggested {
  font-style: normal;
  font-size: 10px;
  line-height: 17px;
  color: #000000;
}

.suggested-items {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 17px;
  color: #000000;
}

.suggest-img-block {
  padding: 10px;
  background-color: #f6f6f6;
}

.suggest-item-card {
  border: 1px solid rgba(196, 196, 196, 0.51);
  border-radius: 10px;
  text-align: center;
}

.suggest-item-card-home {
  margin-top: -12px;
  border: 1px solid rgba(196, 196, 196, 0.35);
  box-shadow: 3px 3px 6px rgba(196, 196, 196, 0.24);
  border-radius: 6px;
}

.suggest-item,
.suggest-item-home {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
   /* width: auto;
  max-width: 100% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative; */

}

.suggest-item-price,
.suggest-item-price-home {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  color: #0083c6;
}

.suggest-item-home,
.suggest-item-price-home {
  margin-bottom: 5px;
  font-size: 9px;
}

.item-code {
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
}

.prod-inc {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  color: #0083c6;
  border: 1px solid rgba(196, 196, 196, 0.4);
  border-radius: 62px;
}

.suggest-addBtn,
.suggest-addBtn:hover,
.suggest-addBtn:focus {
  width: 100%;
  background: #0083c6;
  border: 1px solid #0083c6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
}

/* Bag Selection */

.bag-gif-block {
  padding: 1rem 2rem;
}

.bag-img {
  display: block;
  margin: auto;
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
}

.bag-selection-block {
  height: 75vh;
  padding: 1rem 8rem;
  background: #f5f9fb;
  text-align: center;
}

.please-select-bag {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 52px;
  color: #000000;
}

.qty-inc-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bagSelection-addBtn,
.bagSelection-addBtn:hover,
.bagSelection-addBtn:focus {
  width: 70%;
  margin-top: 0.5rem;
  background: #0083c6;
  border: 1px solid #0083c6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

/* Payment Screen */

.card-payment-img {
  display: block;
  margin: auto;
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
}

.payment-gif-block {
  height: 75vh;
  padding: 4rem 0rem;
  background: #f5f9fb;
}

.payment-method-block {
  text-align: center;
  align-self: center;
}

.waiting-img {
  display: block;
  margin: auto;
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
  cursor: pointer;
}

.please-insert {
  margin-bottom: 1.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 37px;
  text-align: center;
  color: #000000;
}

.remaining-time {
  margin-top: 1rem;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #f76148;
  text-align: center;
}

.seconds-left {
  margin-bottom: 1.5rem;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #929292;
  text-align: center;
}

.go-back {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  color: #0083c6;
}

.payment-successfull {
  margin-top: 2rem;
  /* margin-bottom: 0.5rem; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #000000;
  display: block;
}

/* Bill Type Modes Screen */

.where-would {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal !important;
  color: #000000;
}

.tick-icon {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 205px;
  left: 170px;
}

.billmode-card-active {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  z-index: -1;
}

.billmode-card {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 15px;
  z-index: -1;
}

.billmode-img-div {
  padding: 2rem 1rem;
  background: #eaf3f8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.bill-mode-img {
  width: 156px;
  height: 100px;
}

.bill-mode-text {
  margin-top: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  height: 3vh;
}


.billmode-addBtn,
.billmode-addBtn:hover,
.billmode-addBtn:focus {
  width: 15%;
  margin-top: 0.5rem;
  background: #0083c6;
  border: 1px solid #0083c6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

/* Feedback Screens */
.feedback-img {
  width: 200px;
  height: 200px;
}

.feedback-text {
  margin-top: 3rem;
  font-style: normal;
  font-weight: 600;
  font-size: 2vw;
  line-height: 0px;
  color: black;
}

what-would {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}

.tick-icon-feedback {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 75px;
  left: 280px;
}

.proceed-nextBtn,
.proceed-nextBtn:hover,
.proceed-nextBtn:focus {
  width: 12%;
  margin-top: 0.5rem;
  background: #0083c6;
  border: 1px solid #0083c6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
}

.feedback-card-active {
  background: #ffffff;
  border: 1px solid #0083c6;
  box-shadow: 6px 7px 8px #e7e7e7;
  border-radius: 15px;
  z-index: -1;
}

.feedback-card {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 0px 8px #e7e7e7;
  border-radius: 15px;
  z-index: -1;
}

.feedback-img-div {
  padding: 2rem 1rem;
  background: #fef9e9;
  border-radius: 10px;
}

.emoji-card-active {
  background: #ffffff;
  border: 1px solid #eab000;
  box-shadow: 6px 7px 8px #e7e7e7;
  border-radius: 15px;
  z-index: -1;
}

.tick-icon-emoji {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 205px;
  left: 170px;
}

/* Payment Selection Screen */

.payment-select-block {
  height: 75vh;
  padding: 1rem 2rem;
  background: #f5f9fb;
}

.payment-selection-card {
  height: 45vh;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.53);
  border-radius: 15px;
  text-align: center;
}

.payment-selection-active-card {
  height: 45vh;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 0px 4px #f6f6f6, 3px 3px 10px #c4c4c4;
  border-radius: 15px;
  text-align: center;
}

.tick-icon-payment {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 265px;
  left: 170px;
  z-index: 1;
}

.card-payment-gif {
  height: 100px;
  width: 100%;
  /* display: block;
  margin: auto; */
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
}

.payment-mode-text {
  margin-top: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
}

.payment-selection-card-gif {
  width: 100%;
  height: 120px;
}

.payment-select-desc {
  padding: 1.5rem 1rem;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: rgba(51, 51, 51, 0.8);
}

.current-order-block {
  padding: 1rem 1rem 2rem 2rem;
}

.img-col {
  background-color: #f6f6f6;
  border-radius: 4px;
}

.current-order {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.prod-name {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}

.prod-qty {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
}

.prod-price {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: right;
  color: #0083c6;
}

.prod-discount {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #019f3e;
  text-align: right;
}

.items-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
}

.total-details-div {
  padding: 10px;
  margin: 1rem 1rem 1rem 0rem;
  background: #f5f9fb;
  border-radius: 6px;
}

.subtotal {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #000000;
}

.subtotal-price {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #000000;
  text-align: right;
}

.discount-label {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #019f3e;
}

.discount-price {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #019f3e;
  text-align: right;
}

.total {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #000000;
}

.total-price {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-align: right;
  color: #000000;
}

.continue-btn-cart {
  width: 95%;
  background-color: #0083c6 !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid #0083c6;
}

/* Payment cancel modal */

.are-you {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.yesCancelBtn, .yesCancelBtn:hover, .yesCancelBtn:focus {
  width: 35%;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  border: none;
  color: #000000;
}

.no-btn, .no-btn:hover, .no-btn:focus {
  width: 45%;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #ffffff;
  border: 1px solid #0083c6;
  background: #0083c6;
  border-radius: 3px;
}

/*********************************************** New UI **************************************************************/

.bgColor{
  background: linear-gradient(180deg, #E7F3F9 0%, rgba(231, 243, 249, 0) 87.57%);
  border-radius: 3px;
  }
  .nav-row {
    background-color: #0072AF;
    padding: 5px 50px 5px 50px;
    height: 10vh;
  }
  
  .nav-icon-div {
    display: flex;
    float: right;
    align-items: center;
  }

  /***********Login & Admin Login Screens************/
.main-heading {
  font-size:6.3vw;
  /* line-height: 67px; */
  font-weight: 700;
  text-align: center;
  /* height: 3vh;
  word-wrap: break-word;
  letter-spacing: -2px; */
  color: white;
  padding-top: 4px;
  margin-bottom: 0px;
}


.sub-heading {
  font-size: 2vw;
  line-height: 19px;
  font-weight: 700;
  text-align: center;
  color: rgba(255, 255, 255, 1.43);
  word-spacing: -1px
}

.form-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-top: 4vh; */
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-field-border {
  height: 3px;
  background-color: #fff;
  margin-bottom: -3px;
  position: relative;
}

.input-field {
  position: relative;
  background-color: transparent;
  width: 40vw;
  border: 3px solid #FFFFFF;
  height: 7vh;
  border-radius: 5px;
  border-top: 0px;
  color: #fff;
  padding: 4vh 2vw;
  font-size: 3vh;
}

.field-lable {
  position: absolute;
  top: -16px;
  font-size: 22px;
  color: white;
  left: 5%;
}

.btn-continue {
  margin-top: 2.7vh;
  position: relative;
  background-color: #FFEA28;
  width: 40vw;
  height: 7vh;
  border-radius: 1vh;
  border-top: 0px solid transparent;
  color: black;
  font-size: 3vh;
  font-weight: 500;
}

.keyboard-div {
  /* height: 77vh; */
  width: 100%;
  position: relative;
  top: 3vh;
  margin-bottom: 2rem;
}
/***********admin screen************/
.card {
  cursor: pointer;
  border: 1px solid rgba(196, 196, 196, 0.43);
  width: 20vw;
  height: 42vh;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
}

.row-3-card {
  border: 1px solid rgba(196, 196, 196, 0.43);
  width: 24vw;
  height: 14vh;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 3vh;

}

.mr-img {
  margin-top: 2vh;
  margin-bottom: 1vh;
  width: 10vw;
  height: 12vh;
}

.ir-img {
  margin-top: 2vh;
  margin-bottom: 1vh;
  width: 10.6vw;
  height: 12.7vh;
}

.cashUp-img {
  margin-top: 2vh;
  margin-bottom: 1vh;
  width: 13vw;
  height: 15.2vh;
}

.admin-row1-headings {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 3.4vh;
  line-height: 33px;
  text-align: center;
  text-transform: capitalize;

  color: #000000;

}

.admin-row1-paragraph {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.66)
}
/***********Email Component************/
.email-icon-bg {
  height: 12.6vh;
  width: 9.3vw;
  position: relative;
  left: 50%; /* Adjusted to center horizontally */
  transform: translateX(-50%); /* Added to center horizontally */
}

.email-icon {
  height: 4vh;
  width: 3vw;
  position: absolute; /* Changed to absolute positioning */
  left: 50%; /* Adjusted to center horizontally */
  top: 50%; /* Adjusted to center vertically */
  transform: translate(-50%, -50%); /* Added to center both horizontally and vertically */
}

.email-input {
  position: relative;
  width: 25rem;
  border: 1px solid #0580BD;
  border-radius: 5px;
  color: black;
  font-size: 2rem;
  height: 5rem;
  padding: 1.2rem;
  top: 15%;
}

.email-lable {
  position: absolute;
  top: 60%;
  font-size: 1.3rem;
  color: #A0A8B2;
  left: 3%;
  background-color: white;
  padding: 0 2%;
}

.email-continue-btn {
  position: relative;
  background-color: #0580BD;
  width: 25rem;
  height: 4rem;
  border-radius: 5px;
  color: #fff;
  font-size: 1.4rem;
  top: 16%;
}

.email-keyboard-div {
  height: 18rem;
  width: 100%;
  position: relative;
  top: 27%;
}
/***********Mobile Component************/
.mc-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
}

.mc-left-div {
  position: relative;
  bottom: 5%;
  margin-top: -5%;
  /* left: 4%; */
  bottom: 3rem;
}

.mc-input-field {
  position: relative;
  width: 25rem;
  border: 1px solid #0580BD;
  border-radius: 5px;
  color: black;
  font-size: 2rem;
  height: 5rem;
  padding: 1.2rem;
}

.mc-input-lable {
  position: absolute;
  top: calc(50% - 1.5rem);
  font-size: 1rem;
  color: #A0A8B2;
  left: 10%;
  background-color: white;
  padding: 0 3%;
}

.mc-continue-btn {
  position: relative;
  background-color: #0580BD;
  width: 22rem;
  height: 4rem;
  border-radius: 5px;
  border-top: 0px solid transparent;
  color: #fff;
  font-size: 1.4rem;
  top: 6%;
}

.mc-mobile-icon {
  height: 4vh;
  width: 3vw;
  position: relative;
  right: 51%;
}

.mc-kb-col {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 2%;
}

.mc-kb-div {
  height: 28rem;
  width: 22rem;
  position: relative;
  top: -5%;
}
/***********customer Login************/
.cl-heading {
  font-size: 7vw;
  font-weight: 700;
  text-align: center;
  /* height: 2rem; */
  word-wrap: break-word;
  color: white;
  margin-bottom: 5vh ;
  line-height: 1em !important;
}

.cl-sub-heading {
  font-size: 2vw;
  /* height: 1.688rem; */
  font-weight: 700;
  text-align: center;
  color: rgba(255, 255, 255, 1.43);
  padding-top: 0.313rem;
  margin-bottom: 0.3em !important;
  line-height: 1em !important;
}

.cl-mobile-icon {
  width: 18vw;
  min-height: 23vh;
  background-color: white;
  border-radius: 25px;
  /* display: block; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  padding-top: 4vh;
}

.cl-or-txt {
  width: 18vw;
  min-height: 23vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.cl-email-icon {
  width: 18vw;
  min-height: 23vh;
  background-color: white;
  border-radius: 25px;
  /* display: flex;
  flex-direction: column; */
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  padding-top: 4vh;

}
/***********Customer SignUp************/
.cs-back-btn {
  padding-top: 12px;
  padding-right: 30px;
  color: white;
  font-size: 6vh;
}

.cs-main-heading {
  font-size: 6vw;
  line-height: normal !important;
  font-weight: 700;
  text-align: center;
  /* height: 4.7vh; */
  /* word-wrap: break-word; */
  /* letter-spacing: -3px; */
  color: #ffffff;
}

.cs-sub-heading {
  font-size: 2vw;
  line-height: normal !important;
  font-weight: 700;
  text-align: center;
  color: rgba(255, 255, 255, 1.43);
  /* word-spacing: -1px; */
}
.cs-form-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 3vh;
}

.cs-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
}

.cs-input-field {
  position: relative;
  background-color: transparent;
  width: 40vw;
  border: 3px solid #FFFFFF;
  height: 7vh;
  border-radius: 5px;
  border-top: 0px solid transparent;
  font-size: 3vh;
  color: #fff;
  padding: 4vh 2vw;
}

.cs-continue-btn {
  position: relative;
  background-color: #FFEA28;
  width: 40vw;
  height: 7vh;
  border-radius: 1vh;
  border-top: 0px solid transparent;
  color: black;
  font-size: 3vh;
  
}

.cs-kb-div {
  /* height: 20rem; */
  width: 100%;
  position: relative;
  margin-bottom: 2vh;
}
/***********Card Payment************/
.card-instruction-text {
  margin-bottom: 0rem;
  font-family: Roboto Condensed;
  color: #000000;
  font-size: 20px;
}

.payment-status-text {
  margin-bottom: 0rem;
  font-family: Roboto Condensed;
  color: rgba(0, 0, 0, 0.66);
  font-size: 20px;
  font-weight: 700;
}

.cp-input-field {
  border: 1px solid #0580BD;
  position: relative;
  width: 40vw;
  height: 9vh;
  border-radius: 5px;
  color: black;
  font-size: 3vh;
  padding: 1.5vh;
}

.cp-field-lable {
  position: absolute;
  top: 7%;
  font-size: 1.1rem;
  color: #A0A8B2;
  left: 30%;
  background-color: white;
  padding: 0 2%;
}

.cp-continue-btn {
  position: relative;
  background-color: #0580BD;
  width: 40vw;
  height: 9vh;
  border-radius: 5px;
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 4vh;
}

.cp-tId-field {
  position: relative;
  width: 25rem;
  border: 1px solid #0580BD;
  border-radius: 5px;
  color: black;
  font-size: 1.6rem;
  height: 4.5rem;
  padding: 1.2rem;
  top: 15%;
}

.cp-tId-lable {
  position: absolute;
  top: -8%;
  font-size: 1.3rem;
  color: #A0A8B2;
  left: 3%;
  background-color: white;
  padding: 0 2%;
}

.cp-final-continue-btn {
  position: relative;
  background-color: #0580BD;
  width: 25rem;
  height: 4rem;
  border-radius: 5px;
  color: #fff;
  font-size: 1.4rem;
  top: 16%;
  margin-bottom: 34vh;
}
/***********Payment Selection************/
.ps-left-col {
  height: 77.8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ps-main-heading {
  font-weight: 600;
  font-size: 2vw;
  /* line-height: 28px; */
  color: #000000;
  position: relative;
  top: -6%;
  text-align: center;
}
/***********SignUp************/
.signup-main-heading {
  font-size: 5.9vw;
  line-height: 68px;
  font-weight: 700;
  text-align: center;
  height: 3vh;
  word-wrap: break-word;
  letter-spacing: -1px;
  color: #FFFFFF;
  padding-top: 4px;
}

.signup-sub-heading {
  font-size: 2.1vw;
  line-height: 23PX;
  font-weight: 700;
  text-align: center;
  color: rgba(255, 255, 255, 1.43);
  word-spacing: -1px;
}

.signup-form-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 3vh;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
  padding-bottom: 4vh;
}

.signup-kb-div {
  /* height: 77vh; */
  width: 100%;
  position: relative;
  top: 3vh;
  margin-bottom: 2rem;
}

  
  /***********Payment Screen************/
  .payment-method-block {
    text-align: center;
    align-self: center;
    margin: 4rem;
  }
  
  .payment-success-block {
    text-align: center;
    align-self: center;
    margin: 3rem 0rem;
  }
  
  .card-payment-img {
    display: block;
    margin: auto;
    /* background-color: hsl(0, 0%, 90%); */
    transition: background-color 300ms;
  }
  .payment-successfull{
    font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-transform: capitalize;
  
  color: #000000;
  
  }
  
  /* feedback Screen */
  
  .feedback-img-bg {
    /* margin: 0.5rem 0.5rem; */
    padding: 0.5rem 0;
    /* background: rgba(254, 233, 44, 0.24); */
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 22vh;
  }
  .feedback-block{
    text-align: center;
    align-self: center;
    margin: 6rem 2rem;
  }
  
  /*QR Screen & Card Screen*/
  .qr-heading-text{
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    
    color: rgba(0, 0, 0, 0.66);
     
  }
  .back-btn{
    /* position: absolute; */
  width: 295px;
  height: 45.74px;
  background: #FEE92C;
  border-radius: 7px 7px 7px 0px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  
  color: #000000;
  
  
  }
  .qr-card-block{
    text-align: center;
    align-self: center;
  }
  /*Thank You Screen*/
  .thankyou-page-text{
    font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  
  color: #000000;
  }


  /* ------------------admin-------------- */

  .admin-row1-headings{
    font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 3.4vh;
  line-height: 33px;
  text-align: center;
  text-transform: capitalize;
  
  color: #000000;
  
  }
  .admin-row1-paragraph{
    font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.66)
  }


  :focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 0 !important;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: white !important;
}


.mc-input-lable-gift {
  position: absolute;
  top: calc(10% - 1.5rem);
  font-size: 1.3rem;
  color: #A0A8B2;
  left: 5%;
  background-color: white;
  padding: 0 3%;
}

.mc-kb-div-gift {
  height: 28rem;
  width: 22rem;
  position: relative;
  top: -6%;
}

.mc-input-field-gift {
  position: relative;
  width: 25rem;
  border: 1px solid #0580BD;
  border-radius: 5px;
  color: black;
  font-size: 2rem;
  height: 4rem;
  padding: 1.2rem;
}

.mc-input-lable-gift {
  position: absolute;
  top: calc(10% - 1.5rem);
  font-size: 1.3rem;
  color: #A0A8B2;
  left: 5%;
  background-color: white;
  padding: 0 3%;
}