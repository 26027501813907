.ant-modal-content {
    border-radius: 7px;
};

.qrlogin-btn {
    width: 100%;
    background-color: #0083c6 !important;
    color: #ffffff !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 1px solid #0083c6;
}



.email.hg-theme-default{
    background-color: transparent;
}
.email.simple-keyboard.hg-layout-default .hg-button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1.7rem;
    border-radius: 0.9rem;
    height: 9vh;
    justify-content: center;
    padding: 0px;
    margin: 0.2rem;
}
.email.simple-keyboard.hg-theme-default .hg-row:nth-child(2) {
    margin-left: 2vw;
    margin-right: 2vw;
}
.simple-keyboard.hg-layout-default .hg-button.hg-yellow {
    background: #FFEA28;
    color: black;
    width: 13vw
  }
  .simple-keyboard.hg-layout-default .hg-button.hg-mail {
    width: 13vw
  }
.email.simple-keyboard.hg-theme-default .hg-row:nth-child(4) {
    margin-left: 3vw;
    margin-right: 3vw;
}

.hg-theme-default .hg-button{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1.7rem;
    border-radius: 0.9rem;
    height: 7.6vh;
    justify-content: center;
    padding: 0px;
    margin: 0.2rem;
}



